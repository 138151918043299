import { Fragment, memo, useCallback, useMemo } from 'react'
import {
  StyledBackButton,
  StyledClassDetailsWrapper,
  StyledColoredSpanItem,
  StyledDetailsContainer,
  StyledLegendAnswer,
  StyledLegendAnswersContainer,
  StyledLegendContainer,
  StyledLegendCorrectAnswer,
  StyledLegendQuestion,
  StyledLegendWrapper,
  StyledPageContentWrapper,
  StyledVoiceButton,
  StyledTableCaption,
  StyledTableContainer,
  StyledTableResultItem,
  StyledTableWrapper,
  StyledTitle,
  StyledTitleContent,
  StyledTitleWrapper,
  StyledSwitchWrapper,
  StyledSwitchText,
  ScoreWrapper,
  IconWrapper,
  Icon,
  IconTitle
} from '../../styles'
import ClassDetailsItem from '../../../ClassDetails/components/ClassDetailsItem'
import uuid from 'react-uuid'
import CustomTable from '../../../../components/CustomTable'
import {
  IAiAnalysisDataCefrLevel,
  IAiAnalysisDataMatchQa,
  IAiAnalysisDataProcessGrammar,
  IAiAnalysisDataProcessSynonyms,
  IAiGrammarColoredWord,
  IExtendedResultResponse,
  IPhonemeResultWords,
  ISynonymArr
} from '../../types'
import PronounceItem from '../PronounceItem'
import { useTranslation } from 'react-i18next'
import {
  formatSecondsToHours,
  getCustomChipOptions,
  getPercentageLevel
} from '../../../../utils/helpers'
import { isEmpty } from 'lodash'
import ComingSoonPage from '../ComingSoonPage'
import Loader from '../../../../components/Loader'
import AccordionContent from '../../../../components/AccordionContent'
import { SPEECH_LANGUAGES } from '../../../../utils/constants'
import { Language, UserType } from '../../../Login/types'
import Suggestions from '../Suggestions'
import CustomSwitch from '../../../../components/CustomSwitch'
import Tags from '../../../../components/Tags'
import { getCurrentUserSelector } from '../../../../core/selectors'
import { useAppSelector } from '../../../../hooks'
import AssignedActivities from '../AssignedActivities'
import ScoreProgressBar from '../ScoreProgressBar'
import { ActivityMode } from '../../../../types'
import { Box, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { CustomChip } from '../../../../components/CustomChip'
import { styled } from '@mui/material/styles'
import ColoredWord from '../ColoredWords'

interface IResultDetailsPageProps {
  resultDetails: IExtendedResultResponse | null
  toggleSuggestionsSwitch: (isShow: boolean) => void
  isShowSuggestions: boolean
  onClickBack: () => void
  isLoading?: boolean
}

// todo make the page clean
const CustomTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} children={children} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'none',
    fontSize: '18px',
    border: '1px solid #dadde9'
  }
})

const ResultDetailsPageWrapper = memo<IResultDetailsPageProps>(
  ({
    resultDetails,
    onClickBack,
    isLoading,
    toggleSuggestionsSwitch,
    isShowSuggestions
  }) => {
    const { t } = useTranslation()
    const currentUser = useAppSelector(getCurrentUserSelector)

    const lang = useMemo(() => {
      return resultDetails?.course.language ?? Language.en
    }, [resultDetails])

    const prepareTableHead = () => {
      return [
        {
          name: t('word'),
          ariaLabel: 'Word',
          cellName: 'word',
          sortable: false
        },
        {
          name: t('phonemesReference'),
          ariaLabel: 'Phonemes reference',
          cellName: 'phonemesReference',
          sortable: false
        },
        {
          name: t('phonemesPronounced'),
          ariaLabel: 'Phonemes pronounced',
          cellName: 'phonemesPronounced',
          sortable: false
        }
      ]
    }

    const getHighlightedValues = useCallback(
      (ref: string[], actually: string[]) => {
        return actually.map((actuallyItem, index) => (
          <PronounceItem
            key={uuid()}
            color={actuallyItem === ref[index] ? 'greenLight' : 'redLight'}
          >
            <>'{actuallyItem}'</>
          </PronounceItem>
        ))
      },
      []
    )

    const isVoiceEnabled = useMemo(() => {
      return !!window.SpeechSynthesisUtterance
    }, [])

    const playPhonemeCallback = useCallback(
      (phoneme: string) => {
        if (isVoiceEnabled) {
          const sound = new window.SpeechSynthesisUtterance()
          sound.text = phoneme
          sound.lang = SPEECH_LANGUAGES[lang]
          window.speechSynthesis.speak(sound)
        } else {
          alert('window.SpeechSynthesisUtterance is not exist')
        }
      },
      [lang]
    )

    const prepareTableData = useCallback(
      (resultWordLevel: IPhonemeResultWords[]) => {
        if (!resultWordLevel) return []

        return resultWordLevel.map((item) => {
          return {
            word: (
              <StyledTableResultItem key={uuid()}>
                <span key={uuid()}>{item.word}</span>{' '}
                <span key={uuid()}>
                  Score:{' '}
                  <StyledColoredSpanItem
                    key={uuid()}
                    $color={item.score === 100 ? 'greenDark' : 'redDark'}
                  >
                    {item.score}%
                  </StyledColoredSpanItem>
                </span>
              </StyledTableResultItem>
            ),
            phonemesReference: (
              <StyledTableResultItem key={uuid()}>
                {item.phonemes_reference.map((phoneme) => (
                  <PronounceItem key={uuid()}>
                    <>'{phoneme}'</>
                  </PronounceItem>
                ))}
              </StyledTableResultItem>
            ),
            phonemesPronounced: (
              <StyledTableResultItem key={uuid()}>
                {getHighlightedValues(
                  item.phonemes_reference,
                  item.phonemes_pronounced
                )}
              </StyledTableResultItem>
            )
          }
        })
      },
      [getHighlightedValues]
    )

    const tags = useMemo(() => {
      if (!resultDetails?.activity) return []
      return [
        ...resultDetails?.activity.grammarTags,
        ...resultDetails?.activity.syntaxTags,
        ...resultDetails?.activity.vocabTags
      ]
    }, [resultDetails?.activity])

    const activityMode = useMemo(
      () => resultDetails?.activity?.mode || ActivityMode.guided,
      [resultDetails?.activity?.mode]
    )

    const prepareCorrectedAnswer = useCallback(
      (processGrammar: IAiAnalysisDataProcessGrammar) => {
        const { words_colored_2: correctedAnswer } = processGrammar

        return correctedAnswer?.map((wordObject) => (
          <ColoredWord color={'greenDark'} wordObject={wordObject} />
        ))
      },
      [resultDetails]
    )

    const prepareGaveAnswer = useCallback(
      (processGrammar: IAiAnalysisDataProcessGrammar) => {
        const { words_colored_1: gaveAnswer } = processGrammar

        return gaveAnswer?.map((wordObject) => (
          <ColoredWord color={'redDark'} wordObject={wordObject} />
        ))
      },
      [resultDetails]
    )

    const prepareCefrLevel = useCallback(
      (cefrLevel: IAiAnalysisDataCefrLevel) => {
        return (
          <Fragment>
            <StyledLegendContainer key={uuid()}>
              <div key={uuid()}>
                <span key={uuid()}>{t('cefrLevel')}: </span>
                <span key={uuid()}>{cefrLevel.label}</span>
              </div>
            </StyledLegendContainer>
          </Fragment>
        )
      },
      [resultDetails, t]
    )

    const prepareMatchQa = useCallback(
      (matchQa: IAiAnalysisDataMatchQa) => {
        return (
          <Fragment>
            <StyledLegendContainer key={uuid()}>
              <div key={uuid()}>
                <span key={uuid()}>{t('matchQa')}: </span>
                <StyledColoredSpanItem
                  $color={matchQa.label ? 'greenDark' : 'redDark'}
                  key={uuid()}
                >
                  {matchQa.label ? t('yes') : t('no')}
                </StyledColoredSpanItem>
              </div>
              <div key={uuid()}>
                <span key={uuid()}>{t('matchQaLevel')}: </span>
                <span key={uuid()}>{matchQa.score.toFixed(2)}</span>
              </div>
            </StyledLegendContainer>
          </Fragment>
        )
      },
      [resultDetails, t]
    )

    const prepareGrammar = useCallback(
      (processGrammar: IAiAnalysisDataProcessGrammar) => {
        const { is_same: isSame } = processGrammar
        if (isSame) {
          return (
            <Fragment>
              <StyledLegendContainer key={uuid()}>
                <div key={uuid()}>
                  <span key={uuid()}>{t('processGrammarRightAnswer')}: </span>
                  <StyledColoredSpanItem key={uuid()} $color="greenDark">
                    <span key={uuid()}>{processGrammar.corrected_answer}</span>
                  </StyledColoredSpanItem>
                </div>
              </StyledLegendContainer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <StyledLegendContainer key={uuid()}>
                <div key={uuid()}>
                  <span key={uuid()}>{t('processGrammarAnswer')}: </span>
                  <span key={uuid()}>{prepareGaveAnswer(processGrammar)}</span>
                </div>
                <div key={uuid()}>
                  <span key={uuid()}>
                    {t('processGrammarCorrectedAnswer')}:{' '}
                  </span>
                  <span key={uuid()}>
                    {prepareCorrectedAnswer(processGrammar)}
                  </span>
                </div>
              </StyledLegendContainer>
            </Fragment>
          )
        }
      },
      [resultDetails, t]
    )

    const getTooltipTitle = useCallback(
      (stringsArrs: ISynonymArr | []) => {
        return (
          <StyledLegendContainer>
            <StyledLegendContainer key={uuid()}>
              <div key={uuid()}>
                <span key={uuid()}>{t('word')}</span>
                {' - '}
                <span key={uuid()}>{stringsArrs[0]}</span>
              </div>
            </StyledLegendContainer>
            <StyledLegendContainer key={uuid()}>
              <div key={uuid()}>
                <span key={uuid()}>{t('level')}</span>
                {' - '}
                <span key={uuid()}>{stringsArrs[2]}</span>
              </div>
            </StyledLegendContainer>
            <StyledLegendContainer key={uuid()}>
              <StyledLegendContainer key={uuid()}>
                <span key={uuid()}>{t('synonyms')}:</span>
                <StyledLegendContainer key={uuid()}>
                  {stringsArrs[3]?.map((synonymsArr: any) => {
                    return (
                      <Fragment>
                        <StyledLegendContainer key={uuid()}>
                          <div key={uuid()}>
                            <StyledColoredSpanItem key={uuid()}>
                              {t('word')}
                            </StyledColoredSpanItem>
                            {' - '}
                            <StyledColoredSpanItem
                              key={uuid()}
                              $color="greenDark"
                            >
                              {synonymsArr[0]}
                            </StyledColoredSpanItem>
                          </div>
                        </StyledLegendContainer>
                        <StyledLegendContainer key={uuid()}>
                          <div key={uuid()}>
                            <span key={uuid()}>{t('level')}</span>
                            {' - '}
                            <span key={uuid()}>{synonymsArr[1]}</span>
                          </div>
                        </StyledLegendContainer>
                      </Fragment>
                    )
                  })}
                </StyledLegendContainer>
              </StyledLegendContainer>
            </StyledLegendContainer>
          </StyledLegendContainer>
        )
      },
      [resultDetails]
    )

    const prepareSynonyms = useCallback(
      (processSynonyms: IAiAnalysisDataProcessSynonyms) => {
        const { synonyms, tokens_all: tokens } = processSynonyms
        if (!synonyms.length) {
          return null
        }

        const wordsSynonymObjects = tokens.map((wordArr, index) => {
          const existedSynonyms = synonyms.find(
            (synonymArr) => synonymArr[1] === index
          )
          return {
            word: wordArr[0],
            synonyms: existedSynonyms || [],
            hasSynonym: !!existedSynonyms
          }
        })

        return (
          <Fragment>
            <StyledLegendContainer key={uuid()}>
              <div key={uuid()}>
                <span key={uuid()}>{t('processSynonyms')}: </span>
                <span key={uuid()}>
                  {wordsSynonymObjects.map((wordObject) => (
                    <span key={uuid()}>
                      {wordObject.hasSynonym ? (
                        <CustomTooltip
                          followCursor
                          title={getTooltipTitle(wordObject.synonyms)}
                        >
                          <b key={uuid()}>{wordObject.word}</b>
                        </CustomTooltip>
                      ) : (
                        wordObject.word
                      )}{' '}
                    </span>
                  ))}
                </span>
              </div>
            </StyledLegendContainer>
          </Fragment>
        )
      },
      [resultDetails, t]
    )

    const prepareAnalysis = useCallback(() => {
      if (!resultDetails /*|| activityMode === ActivityMode.free_speech*/)
        return null

      return (
        <Fragment>
          {resultDetails.analysis?.map((details, index) => (
            <StyledDetailsContainer key={uuid()}>
              <StyledTableCaption key={uuid()}>
                {index + 1}. {t('textReference')} : '
                {details.analysisData.answer}'
                {isVoiceEnabled && (
                  <StyledVoiceButton
                    type="button"
                    onClick={() =>
                      playPhonemeCallback(details.analysisData.answer)
                    }
                  >
                    <img src={'/assets/voice.png'} alt="voice icon" />
                  </StyledVoiceButton>
                )}
              </StyledTableCaption>
              <StyledLegendWrapper key={uuid()}>
                <StyledLegendContainer key={uuid()}>
                  {activityMode !== ActivityMode.free_speech && (
                    <>
                      <div>
                        <span>{t('wordsPronounced')}: </span>
                        <span>
                          [
                          {details.analysisData.words.words_pronounced
                            .map((word) => "'" + `${word}` + "'")
                            .join(', ')}
                          ]
                        </span>
                      </div>
                      <div>
                        <span>{t('wordsError')}: </span>
                        <StyledColoredSpanItem
                          key={uuid()}
                          $color={
                            details.analysisData.words.words_error > 0
                              ? 'redDark'
                              : 'greenDark'
                          }
                        >
                          {details.analysisData.words.words_error.toFixed(2) ??
                            'N/A'}
                        </StyledColoredSpanItem>
                      </div>
                    </>
                  )}

                  <div>
                    <span>{t('phonemesPronounced')}: </span>
                    <span>
                      [
                      {details.analysisData.phonemes.phonemes_pronounced
                        ?.map((word) => "'" + `${word}` + "'")
                        .join(', ')}
                      ]
                    </span>
                  </div>
                  <div>
                    <span>{t('phonemesError')}: </span>
                    <StyledColoredSpanItem
                      key={uuid()}
                      $color={
                        details.analysisData.phonemes.phonemes_error > 0
                          ? 'redDark'
                          : 'greenDark'
                      }
                    >
                      {details.analysisData.phonemes.phonemes_error
                        ? details.analysisData.phonemes.phonemes_error.toFixed(
                            2
                          )
                        : 'N/A'}
                    </StyledColoredSpanItem>
                  </div>
                  <div>
                    <span>{t('pronunciationLevel')}: </span>
                    <span>
                      {details.analysisData.pronounceLevel.label_argmax}
                    </span>
                  </div>
                </StyledLegendContainer>
                <StyledLegendAnswersContainer key={uuid()}>
                  <StyledLegendQuestion key={uuid()}>
                    {details.analysisData.question}
                  </StyledLegendQuestion>
                  {activityMode !== ActivityMode.free_speech && (
                    <>
                      <StyledLegendCorrectAnswer key={uuid()}>
                        {t('correctAnswer')}:{' '}
                        {
                          details.question.answers.find(
                            (answer) => answer.isCorrect
                          )?.text
                        }
                      </StyledLegendCorrectAnswer>
                      {details.question.answers
                        .filter((answer) => !answer.isCorrect)
                        .map((answer) => (
                          <StyledLegendAnswer key={uuid()}>
                            {t('incorrectAnswer')}: {answer.text}
                          </StyledLegendAnswer>
                        ))}
                    </>
                  )}
                  {activityMode === ActivityMode.free_speech && (
                    <>
                      {details.analysisData.cefrLevel &&
                        prepareCefrLevel(details.analysisData.cefrLevel)}
                      {details.analysisData.matchQa &&
                        prepareMatchQa(details.analysisData.matchQa)}
                      {details.analysisData?.processGrammar &&
                        prepareGrammar(details.analysisData.processGrammar)}
                      {details.analysisData.processSynonyms &&
                        prepareSynonyms(details.analysisData.processSynonyms)}
                    </>
                  )}
                </StyledLegendAnswersContainer>
              </StyledLegendWrapper>

              <AccordionContent
                hideTitle={t('hidePhonemeAnalysis')}
                showTitle={t('showPhonemeAnalysis')}
              >
                <StyledTableWrapper>
                  <StyledTableContainer>
                    <CustomTable
                      key={uuid()}
                      tableAriaLabel={'Result Details table'}
                      dataCount={
                        details.analysisData.phonemes.result_words_level
                          ? details.analysisData.phonemes.result_words_level
                              .length
                          : 0
                      }
                      tableHead={prepareTableHead()}
                      rowsCount={
                        details.analysisData.phonemes.result_words_level
                          ? details.analysisData.phonemes.result_words_level
                              .length
                          : 0
                      }
                      rows={prepareTableData(
                        details.analysisData.phonemes.result_words_level || []
                      )}
                      tableSubtitle={``}
                    />
                  </StyledTableContainer>
                </StyledTableWrapper>
              </AccordionContent>
            </StyledDetailsContainer>
          ))}
        </Fragment>
      )
    }, [resultDetails, t, isVoiceEnabled, playPhonemeCallback, activityMode])

    const prepareLevel = useCallback(
      (level: string) => {
        const chipOptions = getCustomChipOptions(level)
        return (
          <CustomChip
            aria-label="level"
            bgColor={chipOptions.bgColor}
            label={chipOptions.name}
            textColor={chipOptions.textColor}
            borderColor={chipOptions.borderColor}
            height="20px"
          />
        )
      },
      [getCustomChipOptions]
    )

    const answerCount = useMemo(
      () => resultDetails?.analysis?.length || 0,
      [resultDetails]
    )

    const pronunciationScore = useMemo(() => {
      const phonemeErrorSum =
        resultDetails?.analysis?.reduce(
          (prev, current) =>
            prev + current.analysisData?.phonemes?.phonemes_error || 0,
          0
        ) || 0
      return (1 - phonemeErrorSum / answerCount) * 100
    }, [resultDetails])

    const grammarCorrectnessScore = useMemo(() => {
      const grammarCorrectnessAnswersCount =
        resultDetails?.analysis?.filter(
          (analysisItem) => !analysisItem.analysisData?.processGrammar?.is_same
        )?.length || 0
      return (
        ((answerCount - grammarCorrectnessAnswersCount) / answerCount) * 100
      )
    }, [resultDetails])

    const levelAccuracyScore = useMemo(() => {
      const currentLevel =
        resultDetails?.activity?.level.split('_')[0].toUpperCase() || ''
      const sumOfAnswerPercents =
        resultDetails?.analysis?.reduce(
          (prev, current) =>
            prev +
            getPercentageLevel(
              current?.analysisData?.cefrLevel?.label || '',
              currentLevel
            ),
          0
        ) || 0

      return sumOfAnswerPercents / answerCount
    }, [resultDetails])

    const rightAnswersScore = useMemo(
      () => (resultDetails?.score ? parseInt(resultDetails.score, 10) : 0),
      [resultDetails?.score]
    )

    const overallScore = useMemo(() => {
      const values: number[] = [pronunciationScore]
      if (activityMode === ActivityMode.guided) {
        values.push(rightAnswersScore)
      } else {
        values.push(grammarCorrectnessScore, levelAccuracyScore)
      }
      const sum = values.reduce((a, b) => a + b, 0)
      const score = sum / values.length

      return score > 100 ? 100 : score
    }, [
      activityMode,
      rightAnswersScore,
      pronunciationScore,
      grammarCorrectnessScore,
      levelAccuracyScore
    ])

    return (
      <StyledPageContentWrapper>
        {isLoading && <Loader />}
        <>
          <StyledTitleWrapper>
            <StyledTitleContent>
              <StyledBackButton
                aria-label={'Previous page button'}
                onClick={onClickBack}
              />
              {resultDetails?.activity?.title && (
                <StyledTitle variant={'h2'}>{`${t('detailsOf')}: '${
                  resultDetails.activity.title
                }'`}</StyledTitle>
              )}
            </StyledTitleContent>
            <StyledTitleContent>
              {resultDetails?.finishedAt
                ? new Date(resultDetails?.finishedAt).toLocaleDateString()
                : ''}
            </StyledTitleContent>
          </StyledTitleWrapper>
          {resultDetails && !isEmpty(resultDetails?.analysis) && (
            <>
              <StyledClassDetailsWrapper>
                <ClassDetailsItem
                  name={t('student')}
                  description={resultDetails.student.fullName}
                />
                <ClassDetailsItem
                  name={t('email')}
                  description={resultDetails.student.email}
                />
                <ClassDetailsItem
                  name={t('school')}
                  description={resultDetails.school.longName}
                />
                <ClassDetailsItem
                  name={t('class')}
                  description={resultDetails.class.name}
                />
                <ClassDetailsItem
                  name={t('course')}
                  description={resultDetails.course.name}
                />
                <ClassDetailsItem
                  name={t('level')}
                  description={[prepareLevel(resultDetails.activity.level)]}
                />
                <ClassDetailsItem
                  name={t('timeSpentOnTheActivity')}
                  description={`${formatSecondsToHours(
                    resultDetails?.duration ?? 0
                  )}`}
                />

                <Box style={{ marginTop: 24 }} />

                <ScoreProgressBar
                  iconPath="/assets/overall-score.svg"
                  iconTitle={t('overallScore')}
                  value={overallScore}
                />
                <ScoreProgressBar
                  iconPath="/assets/pronunciation.svg"
                  iconTitle={t('pronunciation')}
                  tooltipText={t('availableOnFreeSpeechMode')}
                  value={pronunciationScore}
                />
                <ScoreProgressBar
                  iconPath="/assets/grammar.svg"
                  iconTitle={t('grammarCorrectness')}
                  tooltipText={t('availableOnFreeSpeechMode')}
                  disabled={activityMode === ActivityMode.guided}
                  value={grammarCorrectnessScore}
                />
                <ScoreProgressBar
                  iconPath="/assets/level-accuracy.svg"
                  iconTitle={t('levelAccuracy')}
                  tooltipText={t('availableOnFreeSpeechMode')}
                  disabled={activityMode === ActivityMode.guided}
                  value={levelAccuracyScore}
                />
                <ScoreProgressBar
                  iconPath="/assets/right-answers.svg"
                  iconTitle={t('rightAnswers')}
                  tooltipText={t('availableOnGuidedMode')}
                  disabled={activityMode === ActivityMode.free_speech}
                  value={rightAnswersScore}
                />
                {tags.length > 0 && (
                  <ScoreWrapper>
                    <IconWrapper>
                      <Icon
                        src="/assets/topic-treated.svg"
                        alt="topic treated icon"
                      />
                      <IconTitle>{t('topicsTreated')}</IconTitle>
                    </IconWrapper>
                    <Tags tags={tags} style={{ maxWidth: 400 }} />
                  </ScoreWrapper>
                )}
              </StyledClassDetailsWrapper>

              {currentUser?.type === UserType.teacher && (
                <StyledSwitchWrapper>
                  <StyledSwitchText>
                    {t('showSuggestionsSwitch')}
                  </StyledSwitchText>
                  <CustomSwitch
                    checked={isShowSuggestions}
                    onChange={() => toggleSuggestionsSwitch(!isShowSuggestions)}
                  />
                </StyledSwitchWrapper>
              )}
              {isShowSuggestions && (
                <Fragment>
                  <Suggestions
                    classId={resultDetails.class.id}
                    studentId={resultDetails.student.id}
                  />
                  <AssignedActivities
                    classId={resultDetails.class.id}
                    studentId={resultDetails.student.id}
                  />
                </Fragment>
              )}
              {prepareAnalysis()}
            </>
          )}
          {!isLoading && isEmpty(resultDetails?.analysis) && <ComingSoonPage />}
        </>
      </StyledPageContentWrapper>
    )
  }
)

export default ResultDetailsPageWrapper
