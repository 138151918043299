import {
  LinearProgress,
  linearProgressClasses,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ProgressWrapper = styled('div')<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    width: '360px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    opacity: $disabled ? 0.5 : 1
  })
)

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 20,
  backgroundColor: theme.palette.customColors.white,
  border: `1px solid ${theme.palette.customColors.violet}`,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.customColors.violet,
    borderRadius: 20
  }
}))

export const TooltipWrapper = styled('div')(() => ({
  minWidth: '20px',
  maxWidth: '40px',
  height: '40px'
}))

export const Procents = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.grayMedium
}))

export const InfoIcon = styled('img')<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    cursor: $disabled ? 'default' : 'pointer',
    width: '100%',
    height: '100%'
  })
)
