import React, { FC, memo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../../containers/Login'
import { APP_ROUTES } from './appRoutes'
import {
  RegisteredUserGuard,
  TeacherRoleGuard,
  UnregisteredUserGuard
} from '../guards'
import Home from '../../containers/Home'
import BaseLayout from '../Layouts/BaseLayout'
import Profile from '../../containers/Profile'
import Schools from '../../containers/Schools'
import MyClasses from '../../containers/MyClasses'
import Courses from '../../containers/Courses'
import Course from '../../containers/UnitsByCourse'
import Results from '../../containers/Results'
import ClassDetails from '../../containers/ClassDetails'
import Activity from '../../containers/Activity'
import EndOfActivity from '../../containers/EndOfActivity'
import Notifications from '../../containers/Notifications'
import Students from '../../containers/Students'
import UnitDetails from '../../containers/UnitDetails'
import ResultDetails from '../../containers/ResultDetails'
import ResultsStudents from '../../containers/ResultsByStudents'
import StudentDetails from '../../containers/StudentDetails'
import StudentEdit from '../../containers/StudentEdit'
import Tickets from '../../containers/AskForAssistance'
import ForgotPassword from '../../containers/ForgotPassword'
import ResetPassword from '../../containers/ResetPassword'
import OidcEram from '../../containers/Oidc'
import Oauth from '../../containers/Oauth'

const Router: FC = memo(() => (
  <BaseLayout>
    <Routes>
      <Route
        path={APP_ROUTES.LOGIN}
        element={
          <UnregisteredUserGuard>
            <Login />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.OIDC_ERAM}
        element={
          <UnregisteredUserGuard>
            <OidcEram />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.OAUTH_MY_LOGIN}
        element={
          <UnregisteredUserGuard>
            <Oauth />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.FORGOT_PASSWORD}
        element={
          <UnregisteredUserGuard>
            <ForgotPassword />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.RESET_PASSWORD}
        element={
          <UnregisteredUserGuard>
            <ResetPassword />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.MAIN}
        element={
          <RegisteredUserGuard>
            <Home />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.PROFILE}
        element={
          <RegisteredUserGuard>
            <Profile />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.SCHOOLS}
        element={
          <RegisteredUserGuard>
            <Schools />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.STUDENTS}
        element={
          <RegisteredUserGuard>
            <Students />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.STUDENT_DETAIL}
        element={
          <RegisteredUserGuard>
            <StudentDetails />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.STUDENT_DETAIL_EDIT}
        element={
          <RegisteredUserGuard>
            <StudentEdit />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.CLASSES}
        element={
          <RegisteredUserGuard>
            <MyClasses />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSES}
        element={
          <RegisteredUserGuard>
            <Courses />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSE_DETAILS}
        element={
          <RegisteredUserGuard>
            <Course />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSE_UNIT_DETAILS}
        element={
          <RegisteredUserGuard>
            <UnitDetails />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.CLASSES + '/:id'}
        element={
          <RegisteredUserGuard>
            <ClassDetails />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.RESULTS}
        element={
          <RegisteredUserGuard>
            <Results />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.RESULTS_ACTIVITY}
        element={
          <RegisteredUserGuard>
            <TeacherRoleGuard>
              <ResultsStudents />
            </TeacherRoleGuard>
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.ACTIVITY_DEEP_RESULT}
        element={
          <RegisteredUserGuard>
            <ResultDetails />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.VR_INTERACTIVE}
        element={
          <RegisteredUserGuard>
            <Activity />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.END_OF_ACTIVITY}
        element={
          <RegisteredUserGuard>
            <EndOfActivity />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.NOTIFICATIONS}
        element={
          <RegisteredUserGuard>
            <Notifications />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.TICKETS}
        element={
          <RegisteredUserGuard>
            <TeacherRoleGuard>
              <Tickets />
            </TeacherRoleGuard>
          </RegisteredUserGuard>
        }
      />
      <Route path="*" element={<Navigate to={APP_ROUTES.MAIN} />} />
    </Routes>
  </BaseLayout>
))

export default Router
