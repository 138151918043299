import React, { memo, useMemo, useState } from 'react'
import {
  StyledClassDetailsItem,
  StyledClassDetailsItemTitle,
  StyledClassDetailsItemDescription,
  StyledDescriptionsWrapper,
  StyledDescriptionItem
} from './styles'
import { MAX_DISPLAY_LINES } from '../../../../utils/constants'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { StyledShowMoreButton } from '../../../../common/styles'

const ClassDetailsItem = (props: {
  name: string
  description: JSX.Element[] | string | string[]
}) => {
  const [isMoreItemsShow, setIsMoreItemsShow] = useState(false)
  const { t } = useTranslation()

  const description = useMemo(() => {
    if (!props.description) return ''

    if (typeof props.description === 'string') {
      return (
        <StyledClassDetailsItemDescription key={uuid()} variant="h3">
          {props.description}
        </StyledClassDetailsItemDescription>
      )
    }

    return (
      <StyledDescriptionsWrapper>
        {props.description.map((item, key) => {
          if (key < 2) {
            return (
              <StyledDescriptionItem key={uuid()}>{item}</StyledDescriptionItem>
            )
          } else if (isMoreItemsShow) {
            return (
              <StyledDescriptionItem key={uuid()}>{item}</StyledDescriptionItem>
            )
          }
        })}
        {props.description.length > MAX_DISPLAY_LINES && (
          <StyledShowMoreButton
            key={uuid()}
            variant={'outlined'}
            color={'primary'}
            onClick={() => setIsMoreItemsShow(!isMoreItemsShow)}
          >
            {isMoreItemsShow
              ? t('showLess')
              : `${t('showMore')} ${
                  props.description.length - MAX_DISPLAY_LINES
                }`}
          </StyledShowMoreButton>
        )}
      </StyledDescriptionsWrapper>
    )
  }, [props.description, isMoreItemsShow])

  return (
    <StyledClassDetailsItem key={uuid()}>
      <StyledClassDetailsItemTitle key={uuid()} variant={'h3'}>
        {props.name}
      </StyledClassDetailsItemTitle>
      {description}
    </StyledClassDetailsItem>
  )
}

export default memo(ClassDetailsItem)
