import React, { useCallback } from 'react'
import { useAppDispatch } from '../../hooks'
import { EramOidcLogin } from './components/EramOidcLogin'
import { getCurrentUser } from '../Login/services'
import { loginByEram } from './services'
import { CODE_CHALLENGE } from './constants'

function OidcEram() {
  const dispatch = useAppDispatch()

  const getTokensByCode = useCallback((code: string) => {
    const codeChallenge = localStorage.getItem(CODE_CHALLENGE) as string
    dispatch(loginByEram({ code, state: 'gar', codeChallenge })).then((res) => {
      if (loginByEram.fulfilled.match(res)) {
        dispatch(getCurrentUser())
        localStorage.removeItem(CODE_CHALLENGE)
      }
    })
  }, [])

  return <EramOidcLogin getTokensByCode={getTokensByCode} />
}

export default OidcEram
