import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const StyledTitleContent = styled('div')(() => ({
  display: 'flex'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.white,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledClassDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '44px',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  padding: '24px',
  width: 'fit-content',
  marginBottom: '8px',
  '@media (max-width: 768px)': {
    width: '100%'
  }
}))

export const StyledDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledTableCaption = styled('div')`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledTableWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '32px',
  width: '60%',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    width: 'unset'
  }
}))

export const StyledTableContainer = styled('div')(() => ({
  width: '100%'
}))
export const StyledLegendWrapper = styled('div')`
  display: flex;
  gap: 64px;
`

export const StyledLegendContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}))

export const StyledLegendAnswersContainer = styled(StyledLegendContainer)`
  gap: 8px;
`

export const StyledLegendQuestion = styled('span')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.customColors.black};
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
`

export const StyledLegendCorrectAnswer = styled(StyledLegendQuestion)`
  margin-bottom: unset;
`

export const StyledLegendAnswer = styled(StyledLegendQuestion)`
  color: ${({ theme }) => theme.palette.customColors.gray};
  margin-bottom: unset;
`

export const StyledTableResultItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'flex-start',
  height: '100%'
}))

interface ICustomSpanItemProps {
  $color?: string
  $bgColor?: string
}
export const StyledColoredSpanItem = styled('span')<ICustomSpanItemProps>(
  ({ theme, $bgColor, $color }) => ({
    textDecoration: 'none',
    backgroundColor: $bgColor
      ? theme.palette.customColors[`${$bgColor}`]
      : 'inherit',
    color: $color ? theme.palette.customColors[`${$color}`] : 'inherit'
  })
)

export const StyledVoiceButton = styled('button')(({ theme }) => ({
  padding: '0',
  textAlign: 'center',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  color: theme.palette.customColors.blue,
  display: 'flex',
  alignItems: 'center'
}))

export const StyledSwitchText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.black,
  marginRight: '24px'
}))

export const StyledSwitchWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content'
}))

export const RecommendationBlock = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderRadius: '16px'
}))

export const ScoreWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '16px'
}))

export const IconWrapper = styled('div')<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    minWidth: '80px',
    maxWidth: '80px',
    opacity: $disabled ? 0.5 : 1
  })
)

export const IconTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.black,
  textAlign: 'center'
}))

export const Icon = styled('img')(() => ({
  width: '70px',
  height: '70px'
}))
