import { LevelType } from '../containers/Home/types'
import { formatDuration } from 'date-fns'
import { EventSourcePolyfill } from 'event-source-polyfill'
import Cookies from 'js-cookie'
import { AssignmentTypes } from '../containers/UnitsByCourse/types'
import i18n, { TFunction } from 'i18next'
import { TicketStatus } from '../containers/AskForAssistance/types'
import {
  ACCESS_TOKEN_NAME,
  GRAMMARS,
  IS_ERAM_USER,
  REFRESH_TOKEN_NAME,
  SYNTAX,
  VOCAB
} from './constants'
import Config from '../config'

export const setStorageValue = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

const getSingleProperty = (object: any, key: any) =>
  object[key] === null || !object.hasOwnProperty(key) ? '' : object[key]

export const getDefaultFormValues = (object: any = {}, keys: any[] = []) =>
  keys.reduce(
    (accumulator, currentKey) => ({
      ...accumulator,
      [currentKey]: getSingleProperty(object, currentKey)
    }),
    {}
  )

export const getCustomChipOptions = (level: string) => {
  switch (level) {
    case LevelType.a1_beginner:
      return {
        name: 'Beginner (A1)',
        bgColor: 'greenLight',
        textColor: 'greenDark',
        borderColor: 'green'
      }
    case LevelType.a2_elementary:
      return {
        name: 'Elementary (A2)',
        bgColor: 'skyLight',
        textColor: 'skyDark',
        borderColor: 'sky'
      }
    case LevelType.b1_preintermediate:
      return {
        name: 'Pre-intermediate (B1)',
        bgColor: 'yellowLight',
        textColor: 'yellowDark',
        borderColor: 'yellow'
      }
    case LevelType.b2_intermediate:
      return {
        name: 'Intermediate (B2)',
        bgColor: 'violetLight',
        textColor: 'violetDark',
        borderColor: 'violet'
      }
    case LevelType.c1_upperIntermediate:
      return {
        name: 'Upper-intermediate (C1)',
        bgColor: 'redLight',
        textColor: 'redDark',
        borderColor: 'red'
      }
    case LevelType.c2_advanced:
      return {
        name: 'Advanced (C2)',
        bgColor: 'blueLight',
        textColor: 'blueDark',
        borderColor: 'blue'
      }
    default:
      return {
        name: 'Beginner (A1)',
        bgColor: 'greenLight',
        textColor: 'greenDark',
        borderColor: 'green'
      }
  }
}

export const getCustomChipTagOptions = (
  tag: string
): {
  label: string
  bgColor: string
  textColor: string
  borderColor: string
} => {
  const grammarsObj = GRAMMARS.find((item) => item.value === tag)
  const vocabObj = VOCAB.find((item) => item.value === tag)
  const syntaxObj = SYNTAX.find((item) => item.value === tag)

  if (grammarsObj) {
    return {
      label: grammarsObj.name,
      bgColor: 'blueLight',
      textColor: 'blueDark',
      borderColor: 'blue'
    }
  }

  if (vocabObj) {
    return {
      label: vocabObj.name,
      bgColor: 'greenLight',
      textColor: 'greenDark',
      borderColor: 'green'
    }
  }

  if (syntaxObj) {
    return {
      label: syntaxObj.name,
      bgColor: 'redLight',
      textColor: 'redDark',
      borderColor: 'red'
    }
  }

  return {
    label: tag,
    bgColor: 'skyLight',
    textColor: 'skyDark',
    borderColor: 'sky'
  }
}

export const getCustomChipStatusOptions = (status: string) => {
  switch (status) {
    case TicketStatus.solved:
      return {
        name: 'Solved',
        bgColor: 'greenLight',
        textColor: 'greenDark',
        borderColor: 'green'
      }
    case TicketStatus.pending:
      return {
        name: 'Pending',
        bgColor: 'yellowLight',
        textColor: 'yellowDark',
        borderColor: 'yellow'
      }
    case TicketStatus.unsolved:
      return {
        name: 'Unsolved',
        bgColor: 'redLight',
        textColor: 'redDark',
        borderColor: 'red'
      }
    case LevelType.c2_advanced:
      return {
        name: 'Advanced (C2)',
        bgColor: 'blueLight',
        textColor: 'blueDark',
        borderColor: 'blue'
      }
    default:
      return {
        name: 'Beginner (A1)',
        bgColor: 'greenLight',
        textColor: 'greenDark',
        borderColor: 'green'
      }
  }
}

const zeroPad = (num: number) => String(num).padStart(2, '0')
export const formatSecondsToHours = (seconds: number) => {
  if (!seconds) {
    return 'N/A'
  }

  const duration = {
    seconds: seconds % 60,
    minutes: Math.floor((seconds / 60) % 60),
    hours: Math.floor(seconds / 3600)
  }

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count)
    }
  })
}

export const CreateEventSourcePolyfill = (path: string) =>
  new EventSourcePolyfill(`${Config.apiUrl + path}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN_NAME)}`
    }
  })

export const getModalTitle = (
  assignmentType: AssignmentTypes,
  t: TFunction
) => {
  if (assignmentType === AssignmentTypes.assignment) {
    return t('assign')
  } else if (assignmentType === AssignmentTypes.deAssignment) {
    return t('deAssign')
  }
}

export const getSubtitle = (assignmentType: AssignmentTypes, t: TFunction) => {
  if (assignmentType === AssignmentTypes.assignment) {
    return t('assignTo')
  } else if (assignmentType === AssignmentTypes.deAssignment) {
    return t('deAssignFrom')
  }
}

export const formatDateByLocale = (date?: Date, emptyTitle?: string) => {
  const emptyDate = emptyTitle || ''
  return date ? new Date(date).toLocaleDateString(i18n.language) : emptyDate
}

export const getDateFormatByLanguage = (language: string) => {
  switch (language) {
    case 'en':
      return 'MM/DD/YYYY'
    case 'fr':
      return 'DD/MM/YYYY'
    case 'it':
      return 'DD/MM/YYYY'
    case 'ko':
      return 'YYYY.MM.DD'
    case 'lv':
      return 'YYYY.MM.DD'
    case 'ru':
      return 'DD.MM.YYYY'
    case 'sp':
      return 'DD/MM/YYYY'
    default:
      return 'DD/MM/YYYY'
  }
}

export const generateRandomString = (length: number) => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~'
  let result = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    result += charset[randomIndex]
  }
  return result
}

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME)
  localStorage.removeItem(REFRESH_TOKEN_NAME)
  Cookies.remove(ACCESS_TOKEN_NAME)
  Cookies.remove(REFRESH_TOKEN_NAME)

  localStorage.removeItem(IS_ERAM_USER)
}

export const setTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
  Cookies.set(ACCESS_TOKEN_NAME, accessToken)
  Cookies.set(REFRESH_TOKEN_NAME, refreshToken)
}

type Level = 'A1' | 'A2' | 'B1' | 'B2' | 'C1' | 'C2' | string

export function getPercentageLevel(
  answeredLevel: Level,
  currentLevel: Level
): number {
  const levelValues: { [key in Level]: number } = {
    A1: 1,
    A2: 2,
    B1: 3,
    B2: 4,
    C1: 5,
    C2: 6
  }
  if (!levelValues) return 0

  const currentLevelValue = levelValues[currentLevel]
  const answeredLevelValue = levelValues[answeredLevel]

  const difference = Math.abs(currentLevelValue - answeredLevelValue)
  // Every -1 level is 80% of current
  return 100 - difference * 20
}
