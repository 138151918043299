import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCurrentUser, login, loginByMyLogin } from '../services'
import { setTokens } from '../../../utils/helpers'
import {
  IAuthState,
  ICurrentUserResponse,
  IUpdateCurrentUserAvatarUrlPayload,
  IUpdateCurrentUserFullNamePayload
} from '../types'
import i18n from 'i18next'
import { loginByEram } from '../../Oidc/services'
import { IS_ERAM_USER } from '../../../utils/constants'

const initialState: IAuthState = {
  isLoading: false,
  isLogIn: false,
  isAdmin: false,
  user: null,
  accessToken: null,
  refreshToken: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: () => initialState,
    resetCurrentUser: (state) => {
      state.user = initialState.user
    },
    updateCurrentUserFullName: (
      state,
      { payload }: PayloadAction<IUpdateCurrentUserFullNamePayload>
    ) => {
      if (state.user) {
        state.user.fullName = payload.fullName
      }
    },
    updateCurrentUserAvatarUrl: (
      state,
      { payload }: PayloadAction<IUpdateCurrentUserAvatarUrlPayload>
    ) => {
      if (state.user) {
        state.user.avatarUrl = payload.avatarUrl
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const { accessToken, refreshToken } = payload
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        setTokens(accessToken, refreshToken)
        state.isLoading = false
        state.isLogIn = true
      }
    )
    builder.addCase(
      loginByMyLogin.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const { accessToken, refreshToken } = payload
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        setTokens(accessToken, refreshToken)
        state.isLoading = false
        state.isLogIn = true
      }
    )
    builder.addCase(
      loginByEram.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const { accessToken, refreshToken } = payload
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        setTokens(accessToken, refreshToken)
        state.isLoading = false
        state.isLogIn = true
      }
    )
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(loginByMyLogin.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(loginByEram.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, { payload }: PayloadAction<ICurrentUserResponse>) => {
        state.isLoading = false
        state.user = payload
        i18n.changeLanguage(payload.language)

        if (payload.ido) {
          localStorage.setItem(IS_ERAM_USER, 'true')
        }
      }
    )
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = authSlice.actions
export default authSlice.reducer
