import axios from 'axios'
import Cookies from 'js-cookie'
import { IS_ERAM_USER, REFRESH_TOKEN_NAME } from '../utils/constants'
import Config from '../config'
import { removeTokens, setTokens } from '../utils/helpers'

const refreshTokenFn = async () => {
  const refreshToken = Cookies.get(REFRESH_TOKEN_NAME)
  const isEramUser = localStorage.getItem(IS_ERAM_USER)

  try {
    const { data } = await axios.post(`${Config.apiUrl}/auth/refresh`, {
      refreshToken
    })

    if (!data?.accessToken || !data?.refreshToken) {
      removeTokens()
    }

    setTokens(data.accessToken, data.refreshToken)

    return data.accessToken
  } catch (error) {
    removeTokens()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error?.response?.status === 403 && isEramUser === 'true') {
      window.location.href = Config.eram.prepareLogoutEndpoint()
    }
  }
}

export const refreshAccessToken = refreshTokenFn
