import { createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { ICommonChunkConfig, IErrorResponse } from '../../../types'
import { ILoginResponse } from '../../Login/types'
import { IEramCodeExchangeRequest } from '../types'

export const loginByEram = createAsyncThunk<
  ILoginResponse,
  IEramCodeExchangeRequest,
  ICommonChunkConfig
>(API_ROUTES.OIDC_ERAM_CODE_EXCHANGE, async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.OIDC_ERAM_CODE_EXCHANGE,
      data: _data
    })
    return response as ILoginResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
