import { memo, useEffect } from 'react'
import Loader from '../../../../components/Loader'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { generateRandomString } from '../../../../utils/helpers'
import Config from '../../../../config'
import { post, get } from '../../../../axios'
import { API_ROUTES } from '../../../../core/router/apiRoutes'
import { CODE_CHALLENGE } from '../../constants'

interface Props {
  getTokensByCode: (code: string) => void
}

export const EramOidcLogin = memo(({ getTokensByCode }: Props) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const generateCodeChallenge = async (verifier: string) => {
    const codeChallenge = await post({
      path: API_ROUTES.OIDC_CODE_CHALLENGE,
      data: { verifier }
    })

    localStorage.setItem(CODE_CHALLENGE, codeChallenge)

    return codeChallenge
  }

  const authorize = async () => {
    const codeVerifier = generateRandomString(128)
    const codeChallenge = await generateCodeChallenge(codeVerifier)

    const authorizationUrl =
      `${Config.eram.authorizationEndpoint}?` +
      `response_type=code&` +
      `client_id=${Config.eram.clientId}&` +
      'scope=openid scope.gar&' +
      'state=gar&' +
      `idRessource=${Config.eram.idRessourse}&` +
      `redirect_uri=${encodeURIComponent(Config.eram.redirectUri)}&` +
      `code_challenge=${codeChallenge}&` +
      `code_challenge_method=S256`

    window.location.href = authorizationUrl
    // await axios.get(authorizationUrl, {
    //   withCredentials: true
    // })
  }

  const fetchRequest = async () => {
    const code = queryParams.get('code')
    if (code) {
      getTokensByCode(code)
    } else {
      await authorize()
    }
  }

  useEffect(() => {
    fetchRequest()
  }, [])

  return <Loader />
})
