import { minHeight, styled as muiStyled } from '@mui/system'

export const StyledMainLayout = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.customColors.bg,
  minHeight: '100vh',
  position: 'relative'
}))

export const StyledContentWrapper = muiStyled('div')(({ theme }) => ({
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  borderRadius: '28px 0px 0px 28px',
  padding: '24px 24px 24px 24px',
  width: '100%',
  minHeight: '100vh',
  height: 'fit-content',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.customColors.white,
  '@media (max-width: 1140px)': {
    padding: '24px 24px 24px 12px'
  },
  '@media (max-width: 600px)': {
    padding: '24px 16px',
    marginBottom: '76px',
    borderRadius: '0px'
  }
}))

export const LinksWrapper = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}))

export const StyledLink = muiStyled('a')(({ theme }) => ({
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.palette.customColors.blue
}))
