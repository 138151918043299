import { IAiGrammarColoredWord } from '../../types'
import { memo } from 'react'
import uuid from 'react-uuid'
import { StyledColoredSpanItem } from '../../styles'

interface IColoredWordsComponentProps {
  wordObject: IAiGrammarColoredWord
  color: string
}

const ColoredWord = memo<IColoredWordsComponentProps>(
  ({ wordObject, color }) => {
    return (
      <span>
        {wordObject.is_punct ? '' : ' '}
        {wordObject.colored ? (
          <b key={uuid()}>
            <StyledColoredSpanItem key={uuid()} $color={color}>
              {wordObject.text}
            </StyledColoredSpanItem>
          </b>
        ) : (
          wordObject.text
        )}
      </span>
    )
  }
)

export default ColoredWord
