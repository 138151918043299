import { FC, memo, ReactNode, useMemo } from 'react'
import {
  BorderLinearProgress,
  InfoIcon,
  Procents,
  ProgressWrapper,
  TooltipWrapper
} from './styles'
import { Icon, IconTitle, IconWrapper, ScoreWrapper } from '../../styles'
import { Tooltip } from '@mui/material'

interface Props {
  iconPath: string
  iconTitle: string
  value: number
  tooltipText?: string
  disabled?: boolean
}

const ScoreProgressBar: FC<Props> = ({
  iconPath,
  iconTitle,
  value,
  tooltipText,
  disabled
}) => {
  const currentValue = useMemo(() => (disabled ? 0 : value), [disabled, value])
  const scoreTitle = useMemo(
    () => `${Math.round(currentValue)}%`,
    [currentValue]
  )

  return (
    <ScoreWrapper>
      <IconWrapper $disabled={disabled}>
        <Icon src={iconPath} alt="score icon" />
        <IconTitle>{iconTitle}</IconTitle>
      </IconWrapper>
      <ProgressWrapper $disabled={disabled}>
        <BorderLinearProgress
          variant="determinate"
          value={currentValue}
          color="primary"
          sx={{ width: '100%' }}
        />
        <Procents>{scoreTitle}</Procents>
      </ProgressWrapper>
      <TooltipWrapper>
        {disabled && tooltipText && (
          <Tooltip title={tooltipText}>
            <InfoIcon src="/assets/info-icon.svg" alt="info-icon" />
          </Tooltip>
        )}
      </TooltipWrapper>
    </ScoreWrapper>
  )
}

export default memo(ScoreProgressBar)
